import React, { ReactNode } from 'react';

import styled from 'styled-components';

const SizedGiftSvg = styled.img`
  margin-right: ${({ theme }) => theme.sizes.lvl1};
  width: ${({ theme }) => theme.sizes.lvl4};
  height: ${({ theme }) => theme.sizes.lvl4};
`;

const ToolTipInner = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  text-transform: uppercase;
  line-height: 1.1;
  font-size: ${({ theme }) => theme.font.sizes.md};
  font-weight: 700;
  &:first-line {
    font-size: ${({ theme }) => theme.font.sizes.xs};
    font-weight: 400;
  }
`;

interface InsideTooltipProps {
  className?: string;
  svgPath: string;
  content: ReactNode;
}

const InsideTooltip = ({ className, svgPath, content }: InsideTooltipProps) => (
  <ToolTipInner className={className}>
    <SizedGiftSvg src={svgPath} />
    <Content>{content}</Content>
  </ToolTipInner>
);

export { InsideTooltip };
export default InsideTooltip;
