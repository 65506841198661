import React, { useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';

const TipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const tooltipColour = 'rgba(250, 247, 242, 0.75)';
const Tip = styled.div<{
  $active?: boolean;
  $absolute: boolean;
  $direction: TooltipDirection;
}>`
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  transition: opacity 0.5s;
  position: ${({ $absolute }) => ($absolute ? 'absolute' : 'relative')};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  padding: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.text};
  background-color: ${tooltipColour};
  white-space: nowrap;
  width: max-content;
  margin-bottom: ${({ $absolute, theme }) =>
    $absolute ? '0' : theme.sizes.lvl4};

  ${({ $direction, $absolute, theme }) => {
    switch ($direction) {
      default:
      case TooltipDirection.UP:
        return `top: -${theme.sizes.lvl2}; 
        left: 50%;
        transform: ${
          $absolute ? 'translateX(-50%) translateY(-100%)' : 'translateX(-50%)'
        };`;
      case TooltipDirection.DOWN:
        return `bottom:  -${theme.sizes.lvl2};
        left: 50%;
        transform: ${
          $absolute ? 'translateX(-50%) translateY(100%)' : 'translateX(-50%)'
        };`;
      case TooltipDirection.LEFT:
        return `left: -${theme.sizes.lvl2};
        top: 50%;
        transform: ${
          $absolute ? 'translateX(-100%) translateY(-50%)' : 'translateY(-50%)'
        };`;

      case TooltipDirection.RIGHT:
        return `right: -${theme.sizes.lvl2};
        top: 50%;
        transform: ${
          $absolute ? 'translateX(100%) translateY(-50%)' : 'translateY(-50%)'
        };`;
    }
  }}

  &::before {
    content: '';
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${({ theme }) => theme.sizes.lvl1};
    margin-left: -${({ theme }) => theme.sizes.lvl1};
    ${({ $direction, theme }) => {
      switch ($direction) {
        default:
        case TooltipDirection.UP:
          return `top: 100%; 
          left: 50%;
          border-top-color: ${tooltipColour}`;
        case TooltipDirection.DOWN:
          return `bottom: 100%; 
          left: 50%;
          border-bottom-color: ${tooltipColour}`;
        case TooltipDirection.LEFT:
          return `right: -${theme.sizes.lvl2};
            top: 50%;
            transform: translateX(0%) translateY(-50%);
            border-left-color: ${tooltipColour}`;
        case TooltipDirection.RIGHT:
          return `left: -${theme.sizes.lvl1};
            top: 50%;
            transform: translateX(0) translateY(-50%);
            border-right-color: ${tooltipColour}`;
      }
    }}
  }
`;

enum TooltipDirection {
  UP,
  DOWN,
  LEFT,
  RIGHT,
}

interface TooltipProps {
  className?: string;
  delay?: number;
  direction?: TooltipDirection;
  alwaysOpen?: boolean;
  absolute?: boolean;
  children: ReactNode;
  content: ReactNode;
}

const Tooltip = ({
  className,
  delay = 0,
  direction = TooltipDirection.UP,
  alwaysOpen = false,
  absolute,
  children,
  content,
}: TooltipProps) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(!!alwaysOpen);
  };

  useEffect(() => {
    if (alwaysOpen) showTip();
  }, [alwaysOpen]);

  return (
    <TipWrapper
      className={className}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      <Tip $active={active} $direction={direction} $absolute={absolute}>
        {content}
      </Tip>
    </TipWrapper>
  );
};

export { Tooltip, TooltipDirection };
export default Tooltip;
