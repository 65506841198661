import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '../layout/Container';
import RegisterForm from '../components/RegisterForm';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.backgroundAlt};
`;

const Inner = styled(Container)`
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.colours.white};
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrap = styled.div<{ $firstItem?: boolean }>`
  display: block;
  margin: 0 auto;
  position: relative;
  margin-top: ${({ theme, $firstItem }) => ($firstItem ? theme.sizes.lvl8 : 0)};
  order: 1;
  ${({ $firstItem }) => ($firstItem ? 'order: 0;' : '')}
  ${({ theme, $firstItem }) => theme.breakpoint('tabletLandscape')`
    margin-top: ${$firstItem ? theme.sizes.lvl6 : 0};
  `}
`;

const Puzzle = styled.div`
  width: ${({ theme }) => theme.sizes.lvl4};
  position: absolute;
  top: -${({ theme }) => theme.sizes.lvl3};
  right: -${({ theme }) => theme.sizes.lvl5};
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    width:  ${({ theme }) => theme.sizes.lvl5};
    right: -${({ theme }) => theme.sizes.lvl6};
  `}
`;

const Subtitle = styled.h2`
  && {
    order: 1;
    margin-bottom: ${({ theme }) => theme.sizes.lvl1};
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      margin-bottom: ${({ theme }) => theme.sizes.lvl2};
      br {
        display: none;
      }
    `}
  }
`;

const PositionedRegisterForm = styled(RegisterForm)`
  order: 2;
`;

interface RegistrationProps {
  className?: string;
  title: ReactNode;
  subtitle: ReactNode;
  inline?: boolean;
  showDecorativeShape?: boolean;
  primaryButtonLabel?: string;
  tooltipInner?: string;
}

const Registration = ({
  className,
  title,
  subtitle,
  inline,
  showDecorativeShape,
  primaryButtonLabel,
  tooltipInner,
}: RegistrationProps) => {
  return (
    <Background className={className}>
      <Inner>
        {subtitle && <Subtitle className="h4">{subtitle}</Subtitle>}
        <TitleWrap $firstItem={showDecorativeShape}>
          {title && <h1 className="h2">{title}</h1>}
          {showDecorativeShape && (
            <Puzzle>
              <StaticImage
                alt="puzzle_piece_decoration"
                src="../images/puzzle_colour.png"
              />
            </Puzzle>
          )}
        </TitleWrap>
        <PositionedRegisterForm
          inline={inline}
          buttonLabel={primaryButtonLabel}
          tooltipInner={tooltipInner}
        />
      </Inner>
    </Background>
  );
};

export default Registration;
