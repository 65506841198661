import React, { useRef } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';

import Container from '../../layout/Container';
import Header from '../../components/LandingHeader';
import RegisterForm from '../../components/RegisterForm';
import { useUserContext } from '../../context/UserContext';
import { useSiteMetaContext } from '../../context/SiteMetaContext';

const Wrapper = styled(Container)`
  box-sizing: border-box;
  height: 60vh;
  padding-top: ${({ theme }) => theme.sizes.sizeByFactor(16)};
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding-top: ${({ theme }) => theme.sizes.sizeByFactor(20)};
`}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    height: 85vh;
    padding-top: ${({ theme }) => theme.sizes.sizeByFactor(15)};
  `}
    ${({ theme }) => theme.breakpoint('desktop')`
    padding-top: ${({ theme }) => theme.sizes.sizeByFactor(20)};
  `}
`;

const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colours.white};
  justify-content: flex-end;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    justify-content: center;
  `}
`;

const Title = styled.h1`
  && {
    padding: ${({ theme }) => theme.sizes.lvl2};
    position: relative;
    max-width: ${({ theme }) => theme.sizes.sizeByFactor(62.5)};
    margin: ${({ theme }) => theme.sizes.lvl10} 0 0 0;
    font-size: 2.2rem;

    ${({ theme }) => theme.breakpoint('tabletLandscape')`
      padding: 0;
      margin: 0 0 ${({ theme }) => theme.sizes.lvl9}  0;
      line-height: 1.1;
      font-size: ${({ theme }) => theme.sizes.sizeByFactor(8)};
    `}
    ${({ theme }) => theme.breakpoint('desktop')`
      max-width: ${({ theme }) => theme.sizes.sizeByFactor(94)};
      font-size: ${({ theme }) => theme.sizes.sizeByFactor(10)};
    `}
  }
`;
const Subtitle = styled.h2`
  && {
    display: none;
    font-size: ${({ theme }) => theme.font.sizes.xl};
    margin-bottom: ${({ theme }) => theme.sizes.lvl4};
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: block;
    `};
  }
`;

const Shadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 40%;
  z-index: -1;
  background: linear-gradient(90deg, #1f2021 0%, transparent 100%);
`;

const FullScreenVideoPlayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  iframe {
    width: 100vw !important;
    height: 56.25vw !important;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-width: 177.77vh;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const DesktopOnlyRegisterForm = styled(RegisterForm)`
  display: none;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
  display: block;
  `};
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-attachment: fixed;
`;

const BackgroundSection = ({ desktop, mobile, className, children }) => {
  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
    desktop.childImageSharp.fluid,
  ];

  const backgroundFluidImageStackMobile = [
    `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
    mobile.childImageSharp.fluid,
  ];

  return (
    <StyledBackgroundImage
      Tag="section"
      className={className}
      fluid={
        isMobile === true
          ? backgroundFluidImageStackMobile
          : backgroundFluidImageStack
      }
      backgroundColor={`#040e18`}
    >
      {children}
    </StyledBackgroundImage>
  );
};

const StyledBackgroundSection = styled(BackgroundSection)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
`;

const LandingIntroSection = ({
  title,
  subtitle,
  buttonLabel,
  tooltipInner,
  landingBg,
  landingBgMobile,
  hideVideo,
  hideNav,
}) => {
  const { landingVideoLoop } = useSiteMetaContext();
  const { isRegistered } = useUserContext();
  const videoRef = useRef();
  const links = [
    {
      label: 'Sign up',
      url: '#signupform',
    },
    {
      label: 'Conference Experts',
      url: '#conferenceexperts',
    },
    {
      label: 'Free gifts',
      url: isRegistered ? '/thankyou#stepGifts' : '#freegifts',
    },
  ];

  return (
    <StyledBackgroundSection desktop={landingBg} mobile={landingBgMobile}>
      <Wrapper>
        <Inner>
          <Header links={hideNav ? [] : links} />
          <Title className="h1">{title}</Title>
          <Subtitle className="h2">{subtitle}</Subtitle>
          <DesktopOnlyRegisterForm
            inline
            leftAligned
            tooltipInner={tooltipInner}
            buttonLabel={buttonLabel}
          />
        </Inner>
      </Wrapper>

      {!hideVideo && (
        <FullScreenVideoPlayer>
          <ReactPlayer
            allowFullScreen
            volume={0}
            ref={videoRef}
            loop
            playing
            url={landingVideoLoop}
            config={{
              vimeo: {
                playerOptions: {
                  playsinline: true,
                },
              },
            }}
          />
        </FullScreenVideoPlayer>
      )}

      <Shadow />
    </StyledBackgroundSection>
  );
};

export default LandingIntroSection;
