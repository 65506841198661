import React, { useRef, useState, ReactNode } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import Container from '../layout/Container';
import RegisterForm from '../components/RegisterForm';
import ScrollTarget from '../components/ScrollTarget';
import Button from '../components/Button';

const Inner = styled(Container)<{ $stickyRegistration: boolean }>`
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ $stickyRegistration, theme }) =>
    $stickyRegistration ? `padding: ${theme.sizes.lvl1};` : ''};
`;

const Background = styled.div<{ $stickyRegistration: boolean }>`
  position: relative;
  display: flex;

  background-color: ${({ theme }) => theme.colours.backgroundAlt};
  color: #faf7f2;
  transition: top 0.5s;

  z-index: ${({ theme, $stickyRegistration }) =>
    $stickyRegistration ? theme.zIndexes.stickyRegistrationForm : 0};

  ${({ $stickyRegistration }) =>
    $stickyRegistration ? 'position: fixed;top: 0;right: 0;left: 0;' : ''};
`;
const RegistrationCardInner = styled.div<{ $stickyRegistration: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ $stickyRegistration }) =>
    $stickyRegistration ? 'flex-start' : 'center'};
  text-align: ${({ $stickyRegistration }) =>
    $stickyRegistration ? 'left' : 'center'};
  justify-content: center;
`;

const Title = styled.h2`
  && {
    text-transform: uppercase;
    margin-bottom: ${({ theme }) => theme.sizes.lvl4};
    ${({ theme }) => theme.breakpoint('tabletLandscape')`
    br {
      display: none;
    }
  `}
  }
`;
const StickyTitle = styled.h2`
  && {
    margin: 0 0 ${({ theme }) => theme.sizes.lvl1};
    font-size: ${({ theme }) => theme.font.sizes.xl};
  }
`;

const Subtitle = styled.h3`
  && {
    font-size: ${({ theme }) => theme.font.sizes.lg};

    ${({ theme }) => theme.breakpoint('desktop')`
      font-size: ${theme.font.sizes.xl};  
    `}
    ${({ theme }) => theme.breakpoint('medium')`
      font-size: ${theme.font.sizes.xxl};  
    `}
  }
`;

const ButtonLink = styled.a`
  text-decoration: none;
`;

interface RegistrationProps {
  className?: string;
  title: ReactNode;
  subtitle: ReactNode;
  showDecorativeShape?: boolean;
  primaryButtonLabel?: string;
  tooltipInner?: string;
}

const StickyRegistrationSection = ({
  className,
  title,
  subtitle,
  primaryButtonLabel = 'Register Now',
  tooltipInner,
}: RegistrationProps) => {
  const [stickyRegistration, setStickyRegistration] = useState(false);
  const registrationRef = useRef<HTMLDivElement>();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = -currPos.y > registrationRef.current.offsetTop;
      if (isShow !== stickyRegistration) setStickyRegistration(isShow);
    },
    [stickyRegistration]
  );

  const mobileScrolled = isMobile && stickyRegistration;
  const mobileUnscrolled = isMobile && !stickyRegistration;
  const desktopScrolled = !isMobile && stickyRegistration;
  const desktopUnscrolled = !isMobile && !stickyRegistration;

  return (
    <>
      <ScrollTarget ref={registrationRef} />
      <Background
        $stickyRegistration={stickyRegistration}
        className={className}
      >
        <Inner $stickyRegistration={stickyRegistration}>
          {(mobileUnscrolled || desktopScrolled || desktopUnscrolled) && (
            <RegistrationCardInner $stickyRegistration={stickyRegistration}>
              {stickyRegistration ? (
                <StickyTitle className="h4">{title}</StickyTitle>
              ) : (
                <>
                  <Subtitle className="h3">{subtitle}</Subtitle>
                  <Title className="h2 strong">{title}</Title>
                </>
              )}

              <RegisterForm
                inline
                slim={stickyRegistration}
                leftAligned={stickyRegistration}
                buttonLabel={primaryButtonLabel}
                tooltipInner={stickyRegistration ? undefined : tooltipInner}
              />
            </RegistrationCardInner>
          )}
          <div />

          {mobileScrolled && (
            <ButtonLink href="#signup">
              <Button fluid>{primaryButtonLabel}</Button>
            </ButtonLink>
          )}
        </Inner>
      </Background>
    </>
  );
};

export default StickyRegistrationSection;
