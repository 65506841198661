import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Link } from 'gatsby';

import { useScript } from '../hooks/useScript';
import { useSiteMetaContext } from '../context/SiteMetaContext';
import { useUserContext } from '../context/UserContext';
import Button from './Button';
import Tooltip, { TooltipDirection } from './Tooltip';
import Loader from './Loader';
import TextInput from './TextInput';

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('Your first name is required')
    .trim()
    .label('Your first name'),
  email: yup
    .string()
    .required('You must supply a valid email')
    .email('You must supply a valid email')
    .trim()
    .label('Your email'),
});

const Wrapper = styled.div`
  width: 100%;
`;
const Form = styled.form<{ $leftAligned?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $leftAligned }) =>
    $leftAligned ? 'flex-start' : 'center'};
`;

const Inner = styled.div<{ $inline?: boolean; $leftAligned?: boolean }>`
  width: 100%;
  max-width: ${({ $inline }) => ($inline ? '100%' : '640px')};
  margin: 0 -${({ theme }) => theme.sizes.lvl0};
  display: block;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    width: ${({ $leftAligned }) => ($leftAligned ? '100%' : '90%')};
    display: ${({ $inline }) => ($inline ? 'flex' : 'block')};
    justify-content: ${({ $leftAligned }) =>
      $leftAligned ? 'flex-start' : 'center'};
  `}
`;
const FormControl = styled.div<{ $leftAligned?: boolean; $slim?: boolean }>`
  flex-grow: ${({ $leftAligned }) => ($leftAligned ? '1' : '0')};
  padding: 0
    ${({ theme, $slim }) =>
      $slim
        ? `${theme.sizes.lvl0} ${theme.sizes.lvl0}`
        : `${theme.sizes.lvl0} ${theme.sizes.lvl2}`};
`;
const FormControlButton = styled(FormControl)<{ $leftAligned: boolean }>`
  flex-grow: 0;
  padding: 0
    ${({ theme, $leftAligned }) =>
      $leftAligned
        ? `${theme.sizes.lvl1} ${theme.sizes.lvl0}`
        : `${theme.sizes.lvl0} ${theme.sizes.lvl1}`};
`;

const StyledTextInput = styled(TextInput)`
  box-sizing: border-box;
`;

const PositionedButton = styled(Button)`
  white-space: nowrap;
`;

const Disclaimer = styled.span<{ $leftAligned?: boolean }>`
  color: rgba(255, 255, 255, 0.4);
  font-size: ${({ theme }) => theme.font.sizes.xs};
  margin: ${({ $leftAligned }) => ($leftAligned ? `0` : `0 auto`)};
`;
const AlreadyRegisteredP = styled.p<{ $leftAligned?: boolean }>`
  a {
    color: white;
  }
  margin: ${({ $leftAligned, theme }) =>
    $leftAligned ? `0 0 0 ${theme.sizes.lvl1}` : `0 auto`};
`;

const MobileTooltip = styled(Tooltip)`
  width: 100%;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: none;
  `}
`;
const DesktopTooltip = styled(Tooltip)`
  width: 100%;
  display: none;
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    display: block; 
  `}
`;

interface RegisterFormProps {
  className?: string;
  id?: string;
  inline?: boolean;
  leftAligned?: boolean;
  slim?: boolean;
  tooltipInner?: ReactNode;
  buttonLabel?: string;
}

const RegisterForm = ({
  id,
  inline,
  className,
  leftAligned,
  slim,
  tooltipInner,
  buttonLabel = 'Register Now',
}: RegisterFormProps) => {
  const { title, infusionsoftRegistrationFormId } = useSiteMetaContext();
  const { isRegistered, isPaid } = useUserContext();

  const status = useScript(
    `https://xz684.infusionsoft.com/app/timezone/timezoneInputJs?xid=${infusionsoftRegistrationFormId}`
  );

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  if (status === 'error') {
    return (
      <AlreadyRegisteredP>
        Error loading registration form. Please try again with different web
        browser.
      </AlreadyRegisteredP>
    );
  }

  if (status !== 'ready') {
    return <Loader />;
  }

  const RegisterButton = (
    <PositionedButton
      type="submit"
      fluid={!leftAligned}
      onClick={async (clickEvent) => {
        try {
          schema.validateSync({
            email,
            firstName,
          });
        } catch (e) {
          clickEvent.preventDefault();
          setError(e.errors[0]);
        }
      }}
    >
      {buttonLabel}
    </PositionedButton>
  );

  return (
    <>
      <div id={id} className={className}>
        {isPaid && (
          <AlreadyRegisteredP $leftAligned={leftAligned}>
            You've already paid for the {title}!{' '}
            <Link to="/agenda">Click here</Link> to access your agenda!
          </AlreadyRegisteredP>
        )}
        {isRegistered && !isPaid && (
          <AlreadyRegisteredP $leftAligned={leftAligned}>
            You've already registered for {title}!{' '}
            <Link to="/thankyou">Click here</Link> to access your free gifts!
          </AlreadyRegisteredP>
        )}
        {!isRegistered && !isPaid && (
          <Wrapper>
            <Form
              accept-charset="UTF-8"
              action={`https://xz684.infusionsoft.com/app/form/process/${infusionsoftRegistrationFormId}`}
              className="infusion-form"
              id={`inf_form_${infusionsoftRegistrationFormId}`}
              method="POST"
              $leftAligned={leftAligned}
            >
              <Inner $inline={inline} $leftAligned={leftAligned}>
                <input
                  name="inf_form_xid"
                  type="hidden"
                  value={infusionsoftRegistrationFormId}
                />
                <input
                  name="inf_form_name"
                  type="hidden"
                  value={`${title}~br~Sign up`}
                />
                <input
                  name="infusionsoft_version"
                  type="hidden"
                  value="1.70.0.405543"
                />
                <FormControl
                  className="infusion-field"
                  $leftAligned={leftAligned}
                  $slim={slim}
                >
                  <StyledTextInput
                    id="inf_field_FirstName"
                    name="inf_field_FirstName"
                    placeholder="First Name"
                    aria-label="First Name"
                    type="text"
                    error={
                      error.toLowerCase().includes('first name') ? error : ''
                    }
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fluid
                  />
                </FormControl>
                <FormControl
                  className="infusion-field"
                  $leftAligned={leftAligned}
                  $slim={slim}
                >
                  <StyledTextInput
                    id="inf_field_Email"
                    name="inf_field_Email"
                    placeholder="Email"
                    aria-label="Email address"
                    error={error.toLowerCase().includes('email') ? error : ''}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    fluid
                  />
                </FormControl>

                <FormControlButton
                  $leftAligned={leftAligned}
                  $slim={slim}
                  className="infusion-submit"
                >
                  {!tooltipInner ? (
                    RegisterButton
                  ) : (
                    <>
                      <MobileTooltip
                        content={tooltipInner}
                        delay={2000}
                        direction={TooltipDirection.DOWN}
                        alwaysOpen
                      >
                        {RegisterButton}
                      </MobileTooltip>
                      <DesktopTooltip
                        content={tooltipInner}
                        delay={2000}
                        absolute
                        alwaysOpen
                        direction={
                          inline ? TooltipDirection.UP : TooltipDirection.RIGHT
                        }
                      >
                        {RegisterButton}
                      </DesktopTooltip>
                    </>
                  )}
                </FormControlButton>
              </Inner>

              <Disclaimer $leftAligned={leftAligned}>
                By submitting this form, I consent to receive offers and related
                promotional communications from Conscious Life. I can withdraw
                consent at any time.
              </Disclaimer>
            </Form>
          </Wrapper>
        )}
      </div>
    </>
  );
};

export default RegisterForm;
