import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '../layout/Container';
import { useSiteMetaContext } from '../context/SiteMetaContext';

const StyledHeader = styled.header`
  z-index: ${({ theme }) => theme.zIndexes.header};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const HeaderInner = styled(Container)`
  display: flex;
  padding-bottom: 0;
  padding-top: 0;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    justify-content: center;
    justify-content: space-between;
  `}
`;

const LogoWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.sizeByFactor(35)};
  padding: ${({ theme }) => theme.sizes.lvl2};
  margin: 0;
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
 
    max-width: ${theme.sizes.sizeByFactor(27.5)};
    margin: 0 0 0 -${({ theme }) => theme.sizes.lvl3};
  `}
  ${({ theme }) => theme.breakpoint('desktop')`
    max-width: ${theme.sizes.sizeByFactor(35)};
  `}
`;

const StyledNav = styled.nav`
  height: ${({ theme }) => theme.sizes.lvl10};

  ul {
    padding: 0;
    display: none;

    ${({ theme }) => theme.breakpoint('tabletPortrait')`
    display:flex;
  `}
    margin: ${({ theme }) => theme.sizes.lvl3} auto 0 auto;
    align-items: center;
    justify-content: flex-end;
    li {
      list-style-type: none;
      margin-left: ${({ theme }) => theme.sizes.lvl2};
      ${({ theme }) => theme.breakpoint('desktop')`
        margin-left: ${theme.sizes.lvl8};
      `}
      a {
        color: ${({ theme }) => theme.colours.white};
      }
    }
  }
`;

const Header = ({ links }) => {
  const { title } = useSiteMetaContext();
  return (
    <StyledHeader>
      <HeaderInner>
        <LogoWrapper>
          <Link to="/">
            <StaticImage
              src="../images/logo.png"
              alt={title}
              placeholder="tracedSVG"
            />
          </Link>
        </LogoWrapper>
        <StyledNav>
          <ul>
            {links.map((link) => (
              <li key={`link_${link.label}`}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </StyledNav>
      </HeaderInner>
    </StyledHeader>
  );
};

Header.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

Header.defaultProps = {
  links: [],
};

export default Header;
